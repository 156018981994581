import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuthContext } from "./Hooks/Auth/useAuthContext";
import LoginPage from "./Pages/LoginPage";
import DrawerSideBar from "./Components/UI/DrawerSideBar";
import SpeedDial from "./Components/UI/SpeedDial";
import { nlNL } from "@mui/material/locale";
import SignupPage from "./Pages/SignupPage";
import BusinessDetails from "./Pages/BusinessDetails";
import Customers from "./Pages/Customers";
import Invoices from "./Pages/Invoices";
import SingleInvoice from "./Pages/SingleInvoice";
import CsvConverter from "./Pages/CsvConverter";
import Inbox from "./Pages/Inbox";
import TransactionRules from "./Pages/TransactionRules";
import Transactions from "./Pages/Transactions";
import AccountingExplanation from "./Pages/AccountingExplanation";

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ff5733",
    },
    secondary: {
      main: "#2a4365",
      contrastText: "white",
    },
  },
  locale: nlNL,
  typography: {
    h1: {
      fontSize: "2rem",
      fontWeight: "bold",
      "@media (max-width:600px)": {
        fontSize: "1.4rem", // Kleinere font-grootte voor mobiel binnen h1
      },
    },
  },
});

function App() {
  const { user } = useAuthContext();

  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <Invoices />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/facturen/:id"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <SingleInvoice />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/bedrijfsgegevens"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <BusinessDetails />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/klanten"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <Customers />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          <Route
            exact
            path="/csv"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <CsvConverter />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/inbox"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <Inbox />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/transactieregels"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <TransactionRules />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/transacties"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <Transactions />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            exact
            path="/uitleg"
            element={
              user ? (
                <DrawerSideBar>
                  <SpeedDial />
                  <AccountingExplanation />
                </DrawerSideBar>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            element={!user ? <LoginPage /> : <Navigate to="/" />}
          />
          <Route
            path="/accountaanmaken"
            element={!user ? <SignupPage /> : <Navigate to="/" />}
          />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
