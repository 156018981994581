const fields = [
  {
    field: "internalReference",
    required: true,
    label: "Interne referentie",
    value: "",
    col: 12,
    display: "textfield",
  },
  {
    field: "customer",
    required: true,
    label: "Klant",
    value: "",
    col: 12,
    display: "autocomplete",
    noOptions: "Voeg eerst een klant toe",
  },
  {
    field: "businessDetails",
    required: true,
    label: "Bedrijfsgegevens",
    value: "",
    col: 12,
    display: "autocomplete",
    noOptions: "Voeg eerst een bedrijf toe",
  },
  {
    field: "invoiceDate",
    required: true,
    label: "Factuurdatum",
    value: "",
    col: 6,
    display: "date",
  },
  {
    field: "invoiceNumber",
    required: true,
    label: "Factuurnummer",
    value: "",
    col: 6,
    display: "textfield",
  },
];

export default fields;
