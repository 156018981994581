import React from "react";
import { Container } from "@mui/material";

const PageContainer = ({ children, width }) => {
  return (
    <Container
      maxWidth={width || "lg"}
      component="main"
      sx={{ overflowX: "auto", marginTop: 5 }}
    >
      {children}
    </Container>
  );
};

export default PageContainer;
