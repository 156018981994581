import { useState } from "react";
import { useRefresh } from "../Hooks/useRefresh";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/transactionRulesFields";
import { DialogModes } from "../Utils/DialogModes";
import PageContainer from "../Components/UI/PageContainer";
import endpoint from "../Utils/endpoint";
import AddEditTransactionRulesDialog from "../Components/Dialogs/AddEditTransactionRulesDialog";

const TransactionRules = () => {
  useRefresh();
  const [openDialog, setOpenDialog] = useState(false);

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title="Transactieregels"
        singular="Transactie"
        endpoint={endpoint.transactionRules}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        initialSort={{ field: "costType", sort: "asc" }}
        density="compact"
      />
      <AddEditTransactionRulesDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
      />
    </PageContainer>
  );
};

export default TransactionRules;
