import AddEditDialog from "../UI/AddEditDialog";
import fields from "../../Utils/Fields/inboxFields";
import endpoint from "../../Utils/endpoint";
import React, { useEffect, useState } from "react";
import { useAppDataContext } from "../../Hooks/AppData/useAppDataContext";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useFetchTableData } from "../../Context/AppData/useFetchTableData";
import { createFilterOptions } from "@mui/material";

const EditInboxDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
  refreshFilter,
}) => {
  function transformData(data) {
    delete data.data;
    return data;
  }
  const { appData } = useAppDataContext();
  const { fetchTableData } = useFetchTableData();
  const [enhancedHeaders, setEnhancedHeaders] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getOptions() {
      const options = await fetchTableData(endpoint.transaction);
      const enhancedTransactions = options
        ?.filter((option) => !option.noInvoice)
        .map((transaction) => {
          return {
            ...transaction,
            // eslint-disable-next-line
            ["value"]: transaction._id,
            // eslint-disable-next-line
            ["label"]: transaction.supplier,
          };
        });

      const filteredOptions = enhancedTransactions.filter(
        (option) =>
          !appData?.inbox?.some(
            (inboxItem) =>
              inboxItem.transactionId === option._id &&
              inboxItem.transactionId !== patchData?.transactionId,
          ),
      );

      setOptions(filteredOptions);
    }
    getOptions();
    // eslint-disable-next-line
  }, [refreshFilter, patchData]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) =>
      `${option.supplier} ${new Date(option.date).toLocaleDateString(
        "nl-NL",
      )} ${option.inclVat}`,
  });

  useEffect(() => {
    const headersWithOptions = fields.map((header) => {
      if (header.field === "transactionId") {
        return {
          ...header,
          options: options,
          filterOptions: filterOptions,
          renderOption: (props, option) => (
            <li {...props} key={option._id}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "12px" }}>
                  <ReceiptLongIcon color="grey" />
                </div>
                <div>
                  <small style={{ display: "block" }}>
                    Datum {new Date(option.date).toLocaleDateString("nl-NL")}
                  </small>
                  <strong style={{ display: "block" }}>
                    {option?.supplier}
                  </strong>
                  <small style={{ display: "block" }}>
                    €{" "}
                    {Math.abs(Number(option?.inclVat))
                      .toFixed(2)
                      .replace(".", ",")}
                  </small>
                </div>
              </div>
            </li>
          ),
        };
      }
      return header;
    });
    setEnhancedHeaders(headersWithOptions);
    // eslint-disable-next-line
  }, [appData]);

  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Inbox"
      fields={enhancedHeaders}
      afterClose={afterClose}
      width="900px"
      endpoint={endpoint.inbox}
      dialogMode={dialogMode}
      patchData={patchData}
      transformData={transformData}
    />
  );
};

export default EditInboxDialog;
