import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ListItemIcon from '@mui/material/ListItemIcon'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

const RowActions = ({
    row,
    onEditCopyRow,
    onDeleteRow,
    allowEdit,
    allowDuplicate,
    allowDelete,
}) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const getMenuItems = (allowEdit, allowDuplicate, allowDelete) => {
        let items = []

        if (allowEdit) {
            items.push({ label: 'Bewerken', icon: 'EditIcon' })
        }

        if (allowDuplicate) {
            items.push({ label: 'Dupliceren', icon: 'ContentCopyIcon' })
        }

        if (allowDelete) {
            items.push({ label: 'Verwijderen', icon: 'DeleteIcon' })
        }

        return items
    }

    const menuItems = getMenuItems(allowEdit, allowDuplicate, allowDelete)

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const getIconComponent = (iconName) => {
        switch (iconName) {
            case 'EditIcon':
                return <EditIcon fontSize="medium" />
            case 'DeleteIcon':
                return <DeleteIcon fontSize="medium" />
            case 'ContentCopyIcon':
                return <ContentCopyIcon fontSize="medium" />
            default:
                return null
        }
    }

    function handleMenuItemClick(label, row) {
        if (label === 'Bewerken') {
            onEditCopyRow({
                row: row,
                mode: 'edit',
            })
        }
        if (label === 'Dupliceren') {
            onEditCopyRow({
                row: row,
                mode: 'copy',
            })
        }
        if (label === 'Verwijderen') {
            onDeleteRow(row)
        }

        handleMenuClose()
    }

    return (
        <div>
            <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleMenuItemClick(item.label, row)
                        }}
                    >
                        {item.icon && (
                            <ListItemIcon>
                                {getIconComponent(item.icon)}
                            </ListItemIcon>
                        )}
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default RowActions
