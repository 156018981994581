import React, { useEffect, useState } from "react";
import { useRefresh } from "../Hooks/useRefresh";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/inboxFields";
import { DialogModes } from "../Utils/DialogModes";
import PageContainer from "../Components/UI/PageContainer";
import endpoint from "../Utils/endpoint";
import EditInboxDialog from "../Components/Dialogs/EditInboxDialog";
import AttachmentDrawer from "../Components/UI/AttachmentDrawer";
import { useAxios } from "../Hooks/useAxios";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import EmailIcon from "@mui/icons-material/Email";
import { useFetchTableData } from "../Context/AppData/useFetchTableData";
import { useSnackbar } from "notistack";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import ConfirmDeleteDialog from "../Components/UI/ConfirmDeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";

const Inbox = () => {
  useRefresh();
  const { Axios } = useAxios();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { fetchTableData } = useFetchTableData();
  const { appData } = useAppDataContext();
  const [refreshFilter, setRefreshFilter] = useState(true);

  function handleClose() {
    setOpenDialog(false);
    setRefreshFilter(!refreshFilter);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  const [file, setFile] = useState();

  function handleRowClick(params) {
    setOpenDrawer(true);
    setFile({
      fileUrl: preloadedFiles[params.row.attachmentId],
      fileData: params.row,
    });
  }

  function onDrawerClose() {
    setOpenDrawer(false);
    setFile();
  }

  const [preloadedFiles, setPreloadedFiles] = useState({});

  const loadAndConvertFiles = async () => {
    try {
      const files = await Axios(`${endpoint.inbox}/files`, "GET");

      const convertedFiles = {};

      files.forEach((file) => {
        const byteArray = new Uint8Array(file.data.data);

        let blob;
        if (file.contentType === "application/pdf") {
          blob = new Blob([byteArray], { type: "application/pdf" });
        } else {
          blob = new Blob([byteArray], { type: file.contentType });
        }

        const url = URL.createObjectURL(blob);
        convertedFiles[file._id] = url;
      });

      setPreloadedFiles(convertedFiles);
    } catch (error) {
      console.error("Fout bij het laden en converteren van bestanden:", error);
    }
  };

  useEffect(() => {
    loadAndConvertFiles();
    // eslint-disable-next-line
  }, []);

  const [checkMailLoader, setCheckMailLoader] = useState(false);

  async function checkMail() {
    setCheckMailLoader(true);
    enqueueSnackbar("Inbox controleren...", {
      variant: "info",
    });
    await Axios(`${endpoint.inbox}/checkMail`, "GET");
    await fetchTableData(endpoint.inbox);
    enqueueSnackbar("Alle bestanden zijn opgehaald", {
      variant: "success",
    });
    await loadAndConvertFiles();
    setCheckMailLoader(false);
  }

  const [deleteAllMailLoader, setDeleteAllMailLoader] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  async function deleteAllMail() {
    setDeleteAllMailLoader(true);
    enqueueSnackbar("Inbox leegmaken...", {
      variant: "info",
    });
    const success = await Axios(`${endpoint.inbox}/delete`, "DELETE");
    if (success) {
      await fetchTableData(endpoint.inbox);
      enqueueSnackbar("Inbox is leeg gemaakt", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Inbox kon niet leeggemaakt worden", {
        variant: "error",
      });
    }
    setDeleteAllMailLoader(false);
    setOpenConfirmDelete(false);
  }

  const tableActions = (
    <div>
      <MenuItem onClick={checkMail} disabled={checkMailLoader}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        Mail controleren
      </MenuItem>
      <MenuItem
        onClick={() => setOpenConfirmDelete(true)}
        disabled={deleteAllMailLoader || appData?.inbox?.length === 0}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        Ibox leegmaken
      </MenuItem>
    </div>
  );

  const [enhancedHeaders, setEnhancedHeaders] = useState([]);

  useEffect(() => {
    const enhancedHeaders = headers.map((header) => {
      if (header.field === "transactionId") {
        return {
          ...header,
          renderCell: (params) => {
            const transaction = appData?.transactions?.find(
              (transaction) => transaction._id === params.value,
            );
            const formattedNumber = new Intl.NumberFormat("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Math.abs(Number(transaction?.inclVat)));
            return (
              <div>
                {transaction ? (
                  <div>
                    {transaction?.supplier} - €{formattedNumber}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          },
        };
      }
      if (header.field === "invoiceNumber") {
        return {
          ...header,
          renderCell: (params) => {
            const transaction = appData?.transactions?.find(
              (transaction) => transaction._id === params.row.transactionId,
            );
            return (
              <div>
                {transaction ? <div>{transaction?.invoiceNumber}</div> : ""}
              </div>
            );
          },
        };
      }
      return header;
    });
    setEnhancedHeaders(enhancedHeaders);
  }, [appData]);

  return (
    <PageContainer>
      <DataTable
        headers={enhancedHeaders}
        title="Inbox"
        endpoint={endpoint.inbox}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        onRowClick={handleRowClick}
        density="compact"
        initialSort={{ field: "date", sort: "desc" }}
        allowDuplicate={false}
        tableActions={tableActions}
        singular="Factuur"
      />
      <EditInboxDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
        refreshFilter={refreshFilter}
      />

      <AttachmentDrawer
        openDrawer={openDrawer}
        onDrawerClose={onDrawerClose}
        file={file}
      />
      <ConfirmDeleteDialog
        title={"Facturen"}
        handleSave={() => deleteAllMail()}
        openDialog={openConfirmDelete}
        width="800px"
        afterclose={() => {
          setOpenConfirmDelete(false);
        }}
      />
    </PageContainer>
  );
};

export default Inbox;
