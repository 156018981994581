export const vatOptions = [
  { label: "0%", value: 0 },
  { label: "9%", value: 9 },
  { label: "21%", value: 21 },
];

export const costTypes = [
  {
    label: "Kosten",
    options: [
      { label: "Hardware >450", value: "hardwareAbove450" },
      { label: "Inventaris >450", value: "inventoryAbove450" },
      { label: "Verbouwingen >450", value: "renovationsAbove450" },
      {
        label: "Machines en installaties >450",
        value: "machinesAndInstallationsAbove450",
      },
      { label: "Lunches en diners", value: "lunchesAndDinners" },
      { label: "Bedrijfskleding", value: "workClothing" },
      { label: "Inhuur personeel", value: "staffHiring" },
      { label: "Kantinekosten", value: "canteenCosts" },
      { label: "Ziektekosten", value: "healthCareCosts" },
      { label: "Opleidingskosten", value: "trainingCosts" },
      { label: "Reiskosten", value: "travelExpenses" },
      { label: "Overige personeelskosten", value: "otherStaffCosts" },
      { label: "Kosten huisvesting", value: "housingCosts" },
      { label: "Onderhoud en reparaties", value: "maintenanceAndRepairs" },
      { label: "Energieverbruik", value: "energyConsumption" },
      { label: "Schoonmaakkosten", value: "cleaningCosts" },
      { label: "Overige huisverstingskosten", value: "otherHousingCosts" },
      { label: "Klein gereedschap", value: "smallTools" },
      { label: "Onderhoud inventaris", value: "inventoryMaintenance" },
      { label: "Verzekering machines", value: "machineryInsurance" },
      { label: "Kantoor benodigdheden", value: "officeSupplies" },
      { label: "Telefoonkosten", value: "telephoneCosts" },
      { label: "Porti", value: "postage" },
      { label: "Drukwerk", value: "printing" },
      { label: "Automatiseringskosten", value: "automationCosts" },
      {
        label: "Contributies en abonnementen",
        value: "subscriptionsAndMemberships",
      },
      { label: "Vaklitaratuur", value: "professionalLiterature" },
      { label: "Inventariskosten", value: "inventoryCosts" },
      { label: "Overige kantoorkosten", value: "otherOfficeCosts" },
      { label: "Lease/ huur auto's", value: "carLeasingAndRental" },
      { label: "Brandstofkosten", value: "fuelCosts" },
      { label: "Onderhoudskosten auto's", value: "carMaintenanceCosts" },
      { label: "Motorijtuigenbelasting", value: "vehicleTax" },
      { label: "Verzekering auto's", value: "carInsurance" },
      { label: "Overige autokosten", value: "otherCarExpenses" },
      {
        label: "Reclame- en advertentiekosten",
        value: "advertisingAndPromotionCosts",
      },
      { label: "Representatiekosten", value: "representationCosts" },
      { label: "Website kosten", value: "websiteCosts" },
      { label: "Overige verkoopkosten", value: "otherSalesCosts" },
      { label: "Verzekeringen", value: "insurances" },
      { label: "Administratiekosten", value: "administrativeCosts" },
      { label: "Juridische kosten", value: "legalFees" },
      { label: "Overige algemene kosten", value: "otherGeneralExpenses" },
      {
        label: "Inkopen geen BTW specificatie",
        value: "purchasesWithoutVATSpecification",
      },
      { label: "Inkopen - BTW hoog", value: "purchasesVATHigh" },
      { label: "Inkopen - BTW laag", value: "purchasesVATLow" },
      { label: "inkopen - binnen EU", value: "purchasesWithinEU" },
      { label: "Inkopen buiten EU", value: "purchasesOutsideEU" },
    ],
  },
  {
    label: "Omzetsoort",
    options: [
      { label: "Omzet geen BTW specificatie", value: "noVAT" },
      {
        label: "Omzet - BTW hoog",
        value: "VATHigh",
      },
      { label: "Omzet - BTW laag", value: "VATLow" },
      {
        label: "Omzet - binnen EU",
        value: "insideEU",
      },
      {
        label: "Omzet - buiten EU",
        value: "outsideEU",
      },
    ],
  },
];

export const yearOptions = [
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
];

export const quarterOptions = [
  { label: "Eerste kwartaal", value: 1 },
  { label: "Tweede kwartaal", value: 2 },
  { label: "Derde kwartaal", value: 3 },
  { label: "Vierde kwartaal", value: 4},
];

