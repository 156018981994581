import { useRefresh } from "../Hooks/useRefresh";
import PageContainer from "../Components/UI/PageContainer";
import CustomStepper from "../Components/UI/Stepper";
import { useAuthContext } from "../Hooks/Auth/useAuthContext";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AccountingExplanation = () => {
  useRefresh();
  const { user } = useAuthContext();

  const steps = [
    {
      label: "Facturen opsturen",
      description: (
        <div>
          Stuur je facturen naar{" "}
          <strong>{user.invoiceEmail || "(geen email gevonden)"}</strong>.{" "}
          <strong>Let op:</strong> alleen bestanden in .pdf, .png en .jpeg van
          max. 16MB zijn ondersteund
        </div>
      ),
    },
    {
      label: "Controleer je inbox",
      description: (
        <div>
          Ga naar je{" "}
          <strong>
            <Link to="/inbox">Inbox</Link>
          </strong>{" "}
          en klik rechtsboven op{" "}
          <MoreVertIcon style={{ verticalAlign: "middle" }} /> en daarna op{" "}
          <strong>Mail controleren</strong> op alle emails binnen te halen
        </div>
      ),
    },
    {
      label: "Configureer de transactieregels",
      description: (
        <div>
          Ga naar{" "}
          <strong>
            <Link to="/transactieregels">transactieregels</Link>
          </strong>{" "}
          en stel de regels in om transacties automatisch te verwerken
        </div>
      ),
    },
    {
      label: "Maak een bank export",
      description: (
        <div>
          Log in bij je bank en maak een <strong>CSV</strong> export
        </div>
      ),
    },
    {
      label: "Importeer je bank export",
      description: (
        <div>
          Klik rechtsonder op de{" "}
          <AddCircleIcon style={{ verticalAlign: "middle" }} color="primary" />{" "}
          en vervolgens op <strong>Banktransacties toevoegen</strong>
        </div>
      ),
    },
    {
      label: "Upload het bestand",
      description: (
        <div>
          Kies het juiste jaar en kwartaal en upload vervolgens de{" "}
          <strong>CSV</strong>
        </div>
      ),
    },
    {
      label: "Verwijder niet relevante transacties",
      description: (
        <div>
          Ga naar de{" "}
          <strong>
            <Link to="/transacties">transacties</Link>
          </strong>{" "}
          en verwijder alle transacties die <strong>niet</strong> relevant zijn
          voor de belastingaangifte (zoals salaris en transacties naar de
          belastingdienst)
        </div>
      ),
    },
    {
      label: "Voeg aanvullende informatie toe",
      description: (
        <div>
          Voeg aanvullende informatie toe aan <strong>transacties</strong> waar
          die nog ontbreekt (zoals belastingtarief en kostensoort)
        </div>
      ),
    },
    {
      label: "Koppel facturen aan transacties",
      description: (
        <div>
          Ga naar je{" "}
          <strong>
            <Link to="/inbox">Inbox</Link>
          </strong>{" "}
          en koppel de facturen aan de bijbehorende transacties
        </div>
      ),
    },
    {
      label: "Download de boekhouding",
      description: (
        <div>
          Als je dit allemaal gedaan hebt dan ga je naar je{" "}
          <strong>
            <Link to="/transacties">transacties</Link>
          </strong>{" "}
          en klik rechtsboven op{" "}
          <MoreVertIcon style={{ verticalAlign: "middle" }} /> en daarna op{" "}
          <strong>Boekhouding downloaden</strong>
        </div>
      ),
    },
    {
      label: "Aanleveren",
      description: (
        <div>
          Lever je boekhouding aan door dit bestand naar de boekhouder te sturen
        </div>
      ),
    },
  ];

  return (
    <PageContainer>
      <CustomStepper steps={steps} />
    </PageContainer>
  );
};

export default AccountingExplanation;
