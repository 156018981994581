import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./Context/Auth/AuthContext";
import { AppDataContextProvider } from "./Context/AppData/AppDataContext";
import SnackbarProviderWrapper from "./Context/UI/useSnackbar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <AppDataContextProvider>
      <SnackbarProviderWrapper>
        <App />
      </SnackbarProviderWrapper>
    </AppDataContextProvider>
  </AuthContextProvider>,
);
