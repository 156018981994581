import { useRefresh } from "../Hooks/useRefresh";
import { useState } from "react";
import DataTable from "../Components/UI/DataTable";
import headers from "../Utils/Fields/customersFields";
import AddEditCustomerDialog from "../Components/Dialogs/AddEditCustomerDialog";
import { DialogModes } from "../Utils/DialogModes";
import PageContainer from "../Components/UI/PageContainer";

const Customers = () => {
  useRefresh();
  const [openDialog, setOpenDialog] = useState(false);

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  return (
    <PageContainer>
      <DataTable
        headers={headers}
        title="Klanten"
        singular="Klant"
        endpoint={"/api/user/customers"}
        autoPageSize
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
      />
      <AddEditCustomerDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
      />
    </PageContainer>
  );
};

export default Customers;
