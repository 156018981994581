import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ButtonToggler({
  options,
  defaultValue,
  passSelectedOptionToParent,
}) {
  const [chosenOption, setChosenOption] = React.useState(defaultValue);

  const handleChange = (event, chosenOption) => {
    setChosenOption(chosenOption);
    console.log(chosenOption);
    passSelectedOptionToParent(chosenOption);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={chosenOption}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {options?.map((option) => (
        <ToggleButton
          value={option.value || option}
          key={option.value || option}
        >
          {option.label || option}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
