import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import { useEffect, useState } from 'react'
import PdfViewer from './PdfViewer'

export default function AttachmentDrawer({
    openDrawer = false,
    file,
    onDrawerClose,
}) {
    const [right, setRight] = React.useState(false)
    const [fileSource, setFileSource] = useState()

    useEffect(() => {
        setRight(openDrawer)
    }, [openDrawer])

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }
        setRight(open)
        if (!open) {
            onDrawerClose()
            setFileSource(null)
        }
    }

    const [fileType, setFileType] = useState()

    useEffect(() => {
        if (file && file.fileUrl) {
            console.log(file.fileUrl)
            setFileSource(file.fileUrl)
            if (file.fileData.contentType === 'application/pdf') {
                setFileType('pdf')
            } else if (file.fileData.contentType.includes('image')) {
                setFileType('image')
            } else {
                setFileType()
            }
        }
    }, [file])

    return (
        <div>
            <Drawer
                anchor="right"
                open={right}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    sx: { width: '595px' },
                }}
            >
                {fileType === 'image' && (
                    <img src={fileSource} alt="Attachment" />
                )}
                {fileType === 'pdf' && <PdfViewer fileSource={fileSource} />}
            </Drawer>
        </div>
    )
}
