import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import FormCreator from "./FormCreator";
import {
  DialogLabels,
  DialogModes,
  DialogMessages,
} from "../../Utils/DialogModes";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

function AddEditDialog({
  openDialog = false,
  title,
  dialogMode = DialogModes.new,
  fields,
  afterClose,
  width = "1200px",
  endpoint,
  patchData,
  transformData,
}) {
  const [open, setOpen] = useState(openDialog);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    afterClose();
    setOpen(false);
  };

  const childRef = useRef();

  const handleSave = async () => {
    setLoading(true);
    if (childRef.current) {
      const isValid = await childRef.current.validateFormFromParent();

      if (!isValid) {
        setLoading(false);
        return;
      }
      const submitForm = await childRef.current.submitFormFromParent();

      if (submitForm === "close") {
        handleClose();
        setLoading(false);
        return;
      }

      if (submitForm) {
        enqueueSnackbar(`${title} succesvol ` + DialogMessages[dialogMode], {
          variant: "success",
        });
        handleClose();
      } else {
        enqueueSnackbar("Oops, er is iets fout gegaan", {
          variant: "error",
        });
      }
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: width, // Set your width here
            },
          },
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            handleSave();
          }
        }}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Typography style={{ fontSize: "25px", fontWeight: 700 }}>
            {title} {DialogLabels[dialogMode]}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {dialogMode && (
            <DialogContentText style={{ paddingBottom: 22 }}>
              Vul de onderstaande velden in
            </DialogContentText>
          )}
          {fields.length > 0 && (
            <FormCreator
              transformData={transformData}
              endpoint={endpoint}
              fields={fields}
              ref={childRef}
              dialogMode={dialogMode}
              patchData={patchData}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sluiten</Button>

          <Button variant="contained" onClick={handleSave} disabled={loading}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span>Opslaan</span>
              {loading && (
                <CircularProgress size="1rem" sx={{ marginLeft: 0.7 }} />
              )}
            </Box>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddEditDialog;
