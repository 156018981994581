import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import * as React from 'react'
import { Link } from 'react-router-dom'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

const SideBarItem = ({ text = '', to = '', noInvoices = false, onClick }) => {
    return (
        <div>
            <ListItem key={text} disablePadding>
                <ListItemButton component={Link} to={to} onClick={onClick}>
                    <ListItemIcon sx={{ color: 'white', minWidth: '40px' }}>
                        <CalendarMonthIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={text}
                        primaryTypographyProps={{
                            sx: { color: 'white', fontSize: '14px' },
                        }}
                        style={{ margin: 0 }}
                    />
                </ListItemButton>
            </ListItem>
        </div>
    )
}

export default SideBarItem
