import AddEditDialog from "../UI/AddEditDialog";
import { useEffect, useState } from "react";
import endpoint from "../../Utils/endpoint";
import headers from "../../Utils/Fields/invoiceFields";
import { useFetchTableData } from "../../Context/AppData/useFetchTableData";
import { useAppDataContext } from "../../Hooks/AppData/useAppDataContext";

const AddEditInvoiceDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
}) => {
  const { fetchTableData } = useFetchTableData();
  const { appData } = useAppDataContext();

  useEffect(() => {
    fetchTableData(endpoint.businessDetails);
    fetchTableData(endpoint.customers);
    // eslint-disable-next-line
  }, []);

  const [customHeaders, setCustomHeaders] = useState([]);

  useEffect(() => {
    if (appData) {
      const updatedHeaders = headers.map((header) => {
        if (appData.customers && header.field === "customer") {
          const customerOptions = appData.customers.map((customer) => {
            return {
              label: customer.companyName,
              value: customer._id,
              key: customer._id,
            };
          });
          return { ...header, options: customerOptions };
        }
        if (appData.businessdetails && header.field === "businessDetails") {
          const businessDetailsOptionsOptions = appData.businessdetails.map(
            (business) => {
              return {
                label: business.companyName,
                value: business._id,
                key: business._id,
              };
            },
          );
          return { ...header, options: businessDetailsOptionsOptions };
        }
        return header;
      });
      setCustomHeaders(updatedHeaders);
    }
  }, [appData]);

  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Factuur"
      fields={customHeaders}
      afterClose={afterClose}
      width="600px"
      endpoint={endpoint.invoices}
      dialogMode={dialogMode}
      patchData={patchData}
    />
  );
};

export default AddEditInvoiceDialog;
