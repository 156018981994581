import { useRefresh } from "../Hooks/useRefresh";
import PageContainer from "../Components/UI/PageContainer";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import _ from "lodash";
import * as XLSX from "xlsx";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Grid from "@mui/material/Grid";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CsvConverter = () => {
  useRefresh();

  const [lineInputQuantity, setLineInputQuantity] = useState();
  const [lineOutputQuantity, setLineOutputQuantity] = useState();

  const csvToJson = (csv) => {
    const lines = csv.split("\n");

    const objects = lines.map((line) => {
      const columns = line.split(",");
      return {
        date: columns[0],
        description: columns[3],
        amount: +columns[10],
        extraInfo: columns[17],
      };
    });

    const preparedLines = objects.filter(
      (obj) => obj.date.trim() !== "" && obj.description !== undefined,
    );
    setLineInputQuantity(preparedLines.length);

    return preparedLines;
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}-${hours}.${minutes}`;
  };

  const createAndDownloadExcel = (revenue, costs) => {
    const workbook = XLSX.utils.book_new();

    const setColumnWidth = (sheet, widths) => {
      sheet["!cols"] = widths.map((w) => ({ wch: w }));
    };

    // Verkoopfacturen tabblad
    const revenueSheet = XLSX.utils.json_to_sheet(revenue);
    setColumnWidth(revenueSheet, [10, 40, 10]);
    XLSX.utils.book_append_sheet(workbook, revenueSheet, "Verkoopfacturen");

    // Inkoopfacturen tabblad
    const costsSheet = XLSX.utils.json_to_sheet(costs);
    setColumnWidth(costsSheet, [10, 40, 10]);
    XLSX.utils.book_append_sheet(workbook, costsSheet, "Inkoopfacturen");

    // Bestand opslaan
    XLSX.writeFile(workbook, `Facturen-${getFormattedDateTime()}.xlsx`);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const csvLines = csvToJson(text);

        for (const csvLine of csvLines) {
          for (const translationRule of csvRulesJson) {
            if (
              csvLine.description
                ?.toLowerCase()
                .includes(translationRule.key.toLowerCase()) ||
              csvLine.extraInfo
                ?.toLowerCase()
                .includes(translationRule.key.toLowerCase())
            ) {
              csvLine.description = translationRule.value;
              delete csvLine.extraInfo;
            }
          }
          if (csvLine.extraInfo) {
            csvLine.description =
              csvLine.description + " - " + csvLine.extraInfo;
            delete csvLine.extraInfo;
          }
        }
        const [costs, revenue] = _.partition(
          csvLines,
          (transaction) => transaction.amount < 0,
        );

        const updatedCosts = costs.map((transaction) => ({
          ...transaction,
          amount: Math.abs(transaction.amount),
        }));
        setLineOutputQuantity(revenue.length + updatedCosts.length);
        setInvoiceRevenue(revenue);
        setInvoiceCosts(updatedCosts);
      };
      reader.readAsText(file);
    }
  };

  const [csvRules, setCsvRules] = useState("");
  const [csvRulesJson, setCsvRulesJson] = useState("");

  const handleRuleChange = (event) => {
    setCsvRules(event.target.value);
    const text = event.target.value;
    const lines = text.split("\n");

    const objects = lines
      .map((line) => {
        const [key, ...valueParts] = line.split(":");
        return { key: key.trim(), value: valueParts.join(":").trim() };
      })
      .filter((obj) => obj.key !== "");

    setCsvRulesJson(objects);
  };

  const [invoiceCosts, setInvoiceCosts] = useState();
  const [invoiceRevenue, setInvoiceRevenue] = useState();

  function downloadConverterCsv() {
    createAndDownloadExcel(invoiceRevenue, invoiceCosts);
  }

  return (
    <PageContainer>
      <Box>
        <TextField
          label="Regels"
          multiline
          rows={10}
          value={csvRules}
          placeholder="Voer hier de CSV regels in"
          fullWidth
          sx={{ marginTop: 1 }}
          onChange={handleRuleChange}
        />
        <Box sx={{ marginTop: 2 }}>
          {!invoiceRevenue || !invoiceCosts ? (
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload csv
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileChange}
                accept=".csv"
              />
            </Button>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12}>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={downloadConverterCsv}
                >
                  Download Csv
                </Button>
                <IconButton
                  aria-label="refresh"
                  onClick={() => {
                    setInvoiceRevenue();
                    setInvoiceCosts();
                    setLineOutputQuantity();
                    setLineOutputQuantity();
                  }}
                  sx={{ marginLeft: 1 }}
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} lg={4}>
                <span style={{ fontSize: "0.8rem", marginLeft: "8px" }}>
                  {lineInputQuantity}/{lineOutputQuantity} regels verwerkt
                </span>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </PageContainer>
  );
};

export default CsvConverter;
