import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { useAxios } from '../../Hooks/useAxios'
import * as React from 'react'
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { useFetchTableData } from '../../Context/AppData/useFetchTableData'
import { DialogModes } from '../../Utils/DialogModes'
import {
    Autocomplete,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Switch,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import nlLocale from 'dayjs/locale/nl'

const FormCreator = forwardRef((props, ref) => {
    const { Axios } = useAxios()
    const { fetchTableData } = useFetchTableData()
    const [formErrors, setFormErrors] = useState({})

    const validateForm = () => {
        return new Promise((resolve) => {
            let isValid = true
            let newErrors = {}

            for (let field of props.fields) {
                if (
                    field.required &&
                    (formData[field.field] === undefined ||
                        formData[field.field] === null ||
                        formData[field.field] === '')
                ) {
                    newErrors[field.field] = 'Dit veld is verplicht'
                    isValid = false
                } else {
                    newErrors[field.field] = ''
                }
            }
            setFormErrors(newErrors)
            resolve(isValid)
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!(await validateForm(formData))) {
            return
        }

        let dataToSubmit = formData

        if (
            props.dialogMode === DialogModes.edit &&
            formData === compareFormData
        ) {
            console.log('Same data')
            return 'close'
        }

        if (props.transformData) {
            dataToSubmit = props.transformData(formData)
        }

        if (props?.patchData?._id) {
            dataToSubmit._id = props.patchData._id
        }

        try {
            if (props.dialogMode === DialogModes.new) {
                await Axios(props.endpoint, 'POST', dataToSubmit)
            } else if (props.dialogMode === DialogModes.edit) {
                await Axios(props.endpoint, 'PUT', dataToSubmit)
            } else {
                await Axios(props.endpoint + '/copy', 'POST', dataToSubmit)
            }

            await fetchTableData(props.endpoint)
            return true
        } catch (error) {
            console.error('Fout bij het indienen van het formulier:', error)
            return false
        }
    }

    const formRef = useRef(null)

    useImperativeHandle(ref, () => ({
        validateFormFromParent: () => {
            return validateForm()
        },
        submitFormFromParent: () => {
            const event = { preventDefault: () => {} }
            return handleSubmit(event)
        },
    }))

    const [formData, setFormData] = useState({})
    const [compareFormData, setCompareFormData] = useState({})

    useEffect(() => {
        if (props.patchData && typeof props.patchData === 'object') {
            const newData = Object.keys(props.patchData).reduce((acc, key) => {
                const value = props.patchData[key]
                if (value && typeof value === 'object' && value._id) {
                    acc[key] = value._id.toString()
                } else {
                    acc[key] = value
                }
                return acc
            }, {})
            setCompareFormData(newData)
            setFormData(newData)
        }
    }, [props.patchData])

    const handleCurrencyChange = (e) => {
        const value = e.target.value
        const regex = /^-?\d*\.?\d{0,2}$/

        if (regex.test(value)) {
            setFormData({
                ...formData,
                [e.target.name]: value,
            })
        }
    }

    return (
        <div>
            <Grid
                container
                spacing={4}
                component="form"
                noValidate
                onSubmit={handleSubmit}
                ref={formRef}
            >
                {props.fields?.map((field) => {
                    if (field.display === 'textfield') {
                        return (
                            <Grid
                                item
                                xs={12}
                                md={field.col || 12}
                                key={field.field}
                                style={{ paddingTop: 0 }}
                            >
                                <TextField
                                    margin="dense"
                                    id={field.field}
                                    required={field.required || false}
                                    label={field.label}
                                    type={field.type || 'text'}
                                    fullWidth
                                    name={field.field}
                                    variant="standard"
                                    value={formData[field.field] || ''}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            [field.field]: e.target.value,
                                        })
                                    }}
                                    disabled={field.readOnly || false}
                                    // InputProps={{
                                    //   readOnly: field.readOnly || false,
                                    // }}
                                    error={!!formErrors[field.field]}
                                    helperText={formErrors[field.field]}
                                />
                            </Grid>
                        )
                    }

                    if (
                        field.display === 'currency' &&
                        field.showInForm !== false
                    ) {
                        return (
                            <Grid
                                item
                                xs={12}
                                md={field.col || 12}
                                key={field.field}
                                style={{ paddingTop: 0 }}
                            >
                                <TextField
                                    margin="dense"
                                    disabled={field.readOnly || false}
                                    id={field.field}
                                    required={field.required || false}
                                    label={field.label}
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                €
                                            </InputAdornment>
                                        ),
                                    }}
                                    name={field.field}
                                    variant="standard"
                                    value={formData[field.field] || ''}
                                    onChange={handleCurrencyChange}
                                    error={!!formErrors[field.field]}
                                    helperText={formErrors[field.field]}
                                />
                            </Grid>
                        )
                    }

                    if (field.display === 'number') {
                        return (
                            <Grid
                                item
                                xs={12}
                                md={field.col || 12}
                                key={field.field}
                                style={{ paddingTop: 0 }}
                            >
                                <TextField
                                    margin="dense"
                                    id={field.field}
                                    required={field.required || false}
                                    label={field.label}
                                    fullWidth
                                    type="number"
                                    name={field.field}
                                    variant="standard"
                                    value={formData[field.field] || ''}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            [field.field]: e.target.value,
                                        })
                                    }}
                                    error={!!formErrors[field.field]}
                                    helperText={formErrors[field.field]}
                                />
                            </Grid>
                        )
                    }

                    if (field.display === 'toggle') {
                        return (
                            <Grid
                                item
                                xs={12}
                                md={field.col || 12}
                                key={field.field}
                                style={{
                                    paddingTop: 0,
                                    display: 'flex',
                                    alignItems: 'end',
                                }}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        required={field.required || false}
                                        disabled={field.readOnly || false}
                                        control={
                                            <Switch
                                                checked={
                                                    !!formData[field.field]
                                                }
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        [field.field]:
                                                            e.target.checked,
                                                    })
                                                }}
                                            />
                                        }
                                        label={field.label}
                                    />
                                </FormGroup>
                            </Grid>
                        )
                    }

                    if (field.display === 'date') {
                        return (
                            <Grid
                                item
                                xs={12}
                                md={field.col || 12}
                                key={field.field}
                                style={{ paddingTop: 0, paddingBottom: 4 }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale={nlLocale}
                                >
                                    <DatePicker
                                        label={field.label}
                                        value={
                                            formData[field.field]
                                                ? dayjs(formData[field.field])
                                                : null
                                        }
                                        onChange={(newValue) => {
                                            setFormData({
                                                ...formData,
                                                [field.field]: newValue
                                                    ? newValue.toISOString()
                                                    : null,
                                            })
                                        }}
                                        disabled={field.readOnly || false}
                                        sx={{ width: '100%' }}
                                        inputFormat="DD-MM-YYYY"
                                        mask="__/__/____"
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                margin: 'dense',
                                                fullWidth: true,
                                                required:
                                                    field.required || false,
                                                name: field.field,
                                                error: !!formErrors[
                                                    field.field
                                                ],
                                                helperText:
                                                    formErrors[field.field],
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        )
                    }

                    if (field.display === 'autocomplete') {
                        if (
                            field.onlyShowOnCreate &&
                            props.dialogMode !== DialogModes.new
                        ) {
                            return null
                        }
                        return (
                            <Grid
                                item
                                xs={12}
                                md={field.col || 12}
                                key={field.field}
                                style={{ paddingTop: 0 }}
                            >
                                <Autocomplete
                                    id={field.field}
                                    options={field.options || []}
                                    getOptionLabel={(option) =>
                                        option.label || ''
                                    }
                                    groupBy={field.groupBy}
                                    noOptionsText={
                                        field.noOptions ||
                                        'Geen gegevens gevonden'
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    filterOptions={
                                        field.filterOptions
                                            ? field.filterOptions
                                            : undefined
                                    }
                                    renderOption={
                                        field.renderOption
                                            ? (props, option) =>
                                                  field.renderOption(
                                                      props,
                                                      option
                                                  )
                                            : undefined
                                    }
                                    value={
                                        field?.options?.find(
                                            (option) =>
                                                option.value ===
                                                formData[field.field]
                                        ) || null
                                    }
                                    onChange={(event, newValue) => {
                                        setFormData({
                                            ...formData,
                                            [field.field]: newValue
                                                ? newValue.value
                                                : '',
                                        })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            autoFocus={field.autoFocus || false}
                                            margin="dense"
                                            required={field.required || false}
                                            label={field.label}
                                            type={field.type || 'text'}
                                            fullWidth
                                            name={field.field}
                                            variant="standard"
                                            error={!!formErrors[field.field]}
                                            helperText={formErrors[field.field]}
                                        />
                                    )}
                                />
                            </Grid>
                        )
                    }
                    return null
                })}
            </Grid>
        </div>
    )
})

export default FormCreator
