import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SideBarItem from "./SideBarItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useLogout } from "../../Hooks/Auth/useLogout";
import { useAuthContext } from "../../Hooks/Auth/useAuthContext";
import { Link } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import LogoutIcon from "@mui/icons-material/Logout";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import logo from "../../Assets/logo.png";
import { useAppDataContext } from "../../Hooks/AppData/useAppDataContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import InfoIcon from "@mui/icons-material/Info";

const drawerWidth = 300;

const StyledLogo = styled("div")(({ theme }) => ({
  height: "37px",
  marginTop: "7px",
  [theme.breakpoints.down("sm")]: {
    height: "29px",
  },
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawerSideBar({ children }) {
  const { logout } = useLogout();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user } = useAuthContext();
  const { appData } = useAppDataContext();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleLogout() {
    handleCloseUserMenu();
    logout();
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: theme.palette.secondary.main }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <StyledLogo>
              <img
                src={logo}
                alt="De Facturen Maker"
                style={{ height: "100%" }}
              />
            </StyledLogo>
          </Link>

          <Box
            sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
            justifyContent="flex-end"
          >
            <Tooltip title="Menu openen">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user?.email?.toUpperCase() || ""}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {user?.accountingModule && (
                <Link
                  to="/inbox"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  <MenuItem key="settings" onClick={handleCloseUserMenu}>
                    <AttachEmailIcon
                      style={{ marginRight: "8px", color: "grey" }}
                    />{" "}
                    <Typography textAlign="center" style={{ color: "inherit" }}>
                      Inbox
                    </Typography>
                  </MenuItem>
                </Link>
              )}

              {user?.accountingModule && (
                <Link
                  to="/transacties"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  <MenuItem key="settings" onClick={handleCloseUserMenu}>
                    <ReceiptLongIcon
                      style={{ marginRight: "8px", color: "grey" }}
                    />{" "}
                    <Typography textAlign="center" style={{ color: "inherit" }}>
                      Transacties
                    </Typography>
                  </MenuItem>
                </Link>
              )}
              {user?.accountingModule && (
                <Link
                  to="/transactieregels"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  <MenuItem key="settings" onClick={handleCloseUserMenu}>
                    <AlignHorizontalLeftIcon
                      style={{ marginRight: "8px", color: "grey" }}
                    />{" "}
                    <Typography textAlign="center" style={{ color: "inherit" }}>
                      Transactieregels
                    </Typography>
                  </MenuItem>
                </Link>
              )}
              {user?.accountingModule && (
                <Link
                  to="/uitleg"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  <MenuItem key="settings" onClick={handleCloseUserMenu}>
                    <InfoIcon style={{ marginRight: "8px", color: "grey" }} />{" "}
                    <Typography textAlign="center" style={{ color: "inherit" }}>
                      Uitleg
                    </Typography>
                  </MenuItem>
                </Link>
              )}
              <Divider />
              {/*{user?.accountingModule && (*/}
              {/*  <Link*/}
              {/*    to="/csv"*/}
              {/*    style={{*/}
              {/*      textDecoration: "none",*/}
              {/*      color: "rgba(0, 0, 0, 0.87)",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <MenuItem key="settings" onClick={handleCloseUserMenu}>*/}
              {/*      <AttachFileIcon*/}
              {/*        style={{ marginRight: "8px", color: "grey" }}*/}
              {/*      />{" "}*/}
              {/*      <Typography textAlign="center" style={{ color: "inherit" }}>*/}
              {/*        Csv Converter*/}
              {/*      </Typography>*/}
              {/*    </MenuItem>*/}
              {/*  </Link>*/}
              {/*)}*/}
              <Link
                to="/bedrijfsgegevens"
                style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
              >
                <MenuItem key="settings" onClick={handleCloseUserMenu}>
                  <DescriptionIcon
                    style={{ marginRight: "8px", color: "grey" }}
                  />{" "}
                  <Typography textAlign="center" style={{ color: "inherit" }}>
                    Bedrijfsgegevens
                  </Typography>
                </MenuItem>
              </Link>
              <Link
                to="/klanten"
                style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
              >
                <MenuItem key="settings" onClick={handleCloseUserMenu}>
                  <AddBusinessIcon
                    style={{ marginRight: "8px", color: "grey" }}
                  />{" "}
                  <Typography textAlign="center" style={{ color: "inherit" }}>
                    Klanten
                  </Typography>
                </MenuItem>
              </Link>
              <Divider />
              <MenuItem key="logout" onClick={handleLogout}>
                <LogoutIcon style={{ marginRight: "8px", color: "grey" }} />{" "}
                <Typography textAlign="center">Uitloggen</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: theme.palette.secondary.dark,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon sx={{ color: "white" }} />
            ) : (
              <ChevronRightIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ borderColor: "white" }} />
        <List>
          {appData?.invoices?.length > 0 ? (
            appData?.invoices?.map((invoice) => (
              <SideBarItem
                key={invoice._id}
                text={invoice.internalReference}
                to={`/facturen/${invoice._id}`}
                onClick={() => {
                  if (!isLargeScreen) {
                    handleDrawerClose();
                  }
                }}
              />
            ))
          ) : (
            <SideBarItem
              text="Geen facturen gevonden..."
              to="/"
              onClick={() => {
                if (!isLargeScreen) {
                  handleDrawerClose();
                }
              }}
            />
          )}
        </List>
        <Divider sx={{ borderColor: "white" }} />
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
