import DescriptionIcon from "@mui/icons-material/Description";

const fields = [
  {
    field: "date",
    required: true,
    label: "Uploaddatum",
    value: "",
    col: 12,
    display: "date",
    width: 150,
    readOnly: true,
  },
  {
    field: "filename",
    label: "Bestandsnaam",
    value: "",
    col: 12,
    display: "textfield",
    readOnly: true,
  },
  {
    field: "invoiceNumber",
    label: "Factuurnummer",
    value: "",
    width: 150,
    sortable: false,
  },
  {
    field: "transactionId",
    label: "Gekoppelde transactie",
    value: "",
    col: 12,
    display: "autocomplete",
    noOptions: "Geen transactions gevonden",
    width: 350,
    autoFocus: true,
  },
  {
    field: "invoicePresent",
    label: "Status",
    sortable: false,
    width: 75,
    renderCell: (params) => {
      if (params.row.transactionId) {
        return <DescriptionIcon style={{ color: "#25c781" }} />;
      }
      return <DescriptionIcon style={{ color: "lightgrey" }} />;
    },
  },
];

export default fields;
