import AddEditDialog from "../UI/AddEditDialog";
import fields from "../../Utils/Fields/customersFields";

const AddEditBusinessDetailsDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
}) => {
  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Klant"
      fields={fields}
      afterClose={afterClose}
      width="900px"
      endpoint="/api/user/customers"
      dialogMode={dialogMode}
      patchData={patchData}
    />
  );
};

export default AddEditBusinessDetailsDialog;
