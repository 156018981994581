import { vatOptions, costTypes } from "../options";

const fields = [
  {
    field: "findField",
    required: true,
    label: "Woord om op te zoeken",
    value: "",
    col: 6,
    display: "textfield",
  },
  {
    field: "replaceField",
    required: true,
    label: "Woord om te vervangen",
    value: "",
    col: 6,
    display: "textfield",
  },
  {
    field: "costType",
    label: "Soort",
    value: "",
    col: 6,
    display: "autocomplete",
    options: costTypes.flatMap((type) => type.options),
    groupBy: (option) => {
      const group = costTypes.find((type) => type.options.includes(option));
      return group ? group.label : undefined;
    },
    renderCell: (params) => {
      let costTypeLabel = "";
      for (const type of costTypes) {
        const costType = type.options.find(
          (option) => option.value === params.value,
        );
        if (costType) {
          costTypeLabel = costType.label;
          break;
        }
      }
      return <div>{costTypeLabel}</div>;
    },
  },
  {
    field: "vatAmount",
    label: "Belastingtarief",
    col: 6,
    display: "autocomplete",
    width: 150,
    options: vatOptions,
    renderCell: (params) => {
      const vat = vatOptions.find((vat) => vat.value === params.value);
      return <div>{vat?.label}</div>;
    },
  },
];

export default fields;
