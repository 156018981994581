import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";

import RefreshIcon from "@mui/icons-material/Refresh";
import { useFetchTableData } from "../../Context/AppData/useFetchTableData";
import Box from "@mui/material/Box";

const TableActions = ({ endpoint, tableActions, isLoading }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { fetchTableData } = useFetchTableData();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function handleRefresh() {
    isLoading(true);
    await fetchTableData(endpoint);
    handleMenuClose();
    isLoading(false);
  }

  return (
    <div>
      {!tableActions ? (
        <Box>
          <IconButton
            aria-label="Verversen"
            aria-controls="vertical-more-options-menu"
            aria-haspopup="true"
            onClick={() => handleRefresh()}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleRefresh}>
              <ListItemIcon>
                <RefreshIcon />
              </ListItemIcon>
              Verversen
            </MenuItem>
            {tableActions}
          </Menu>
        </Box>
      )}
    </div>
  );
};

export default TableActions;
