import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import endpoint from "../../Utils/endpoint";
import { useAxios } from "../../Hooks/useAxios";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";

const DownloadButton = ({ id, fileName, disabled = false }) => {
  const [loading, setLoading] = React.useState(false);
  const { Axios } = useAxios();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  async function handleDownload() {
    setLoading(true);

    try {
      await Axios(
        `${endpoint.invoiceDescriptions}/${id}/download`,
        "DOWNLOAD",
        `${fileName}.pdf`,
      );
    } catch (error) {
      console.error("Download error:", error);
    }

    setLoading(false);
  }

  return (
    <Box sx={{ position: "relative" }}>
      {isLargeScreen ? (
        <div>
          <Button
            variant="contained"
            onClick={handleDownload}
            disabled={disabled || loading}
          >
            Download Factuur
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </div>
      ) : (
        <div>
          <IconButton disabled={loading || disabled} onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-5px",
              }}
            />
          )}
        </div>
      )}
    </Box>
  );
};

export default DownloadButton;
