import { useAxios } from "../../Hooks/useAxios";
import { useAppDataContext } from "../../Hooks/AppData/useAppDataContext";

export const useFetchTableData = () => {
  const { Axios } = useAxios();
  const { dispatch } = useAppDataContext();

  const fetchTableData = async (endpoint) => {
    let appDataName = endpoint?.split("/").pop();
    if (appDataName.includes("?")) {
      appDataName = appDataName.split("?")[0];
    }
    const tableData = await Axios(endpoint, "GET");
    dispatch({
      type: "SET_APP_DATA",
      payload: { dataName: appDataName, data: tableData },
    });

    return tableData;
  };

  return { fetchTableData };
};
