const endpoint = {
  businessDetails: "/api/user/businessdetails",
  customers: "/api/user/customers",
  invoices: "/api/user/invoices",
  invoiceDescriptions: "/api/user/invoicedescriptions",
  inbox: "/api/user/inbox",
  transactionRules: "/api/user/transactionrules",
  transaction: "/api/user/transactions",
};

export default endpoint;
