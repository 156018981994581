import AddEditDialog from "../UI/AddEditDialog";
import fields from "../../Utils/Fields/transactionFields";
import endpoint from "../../Utils/endpoint";

const AddEditTransactionDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
}) => {
  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Transactie"
      fields={fields}
      afterClose={afterClose}
      width="900px"
      endpoint={`${endpoint.transaction}/edit`}
      dialogMode={dialogMode}
      patchData={patchData}
    />
  );
};

export default AddEditTransactionDialog;
