import * as React from "react";
import {
  DataGrid,
  nlNL,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridPagination,
} from "@mui/x-data-grid";
import { styled } from "@mui/system";
import CustomNoRowsOverlay from "./NoRows";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useAppDataContext } from "../../Hooks/AppData/useAppDataContext";
import { useFetchTableData } from "../../Context/AppData/useFetchTableData";
import RowActions from "./RowActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import formatToDutchDate from "../../Utils/FormatToDutchDate";
import TableActions from "./TableActions";
import ConfirmDeleteTableRowDialog from "./ConfirmDeleteTableRowDialog";

const StyledDataGrid = styled(DataGrid)({
  width: "100%",
  overflowX: "hidden",
});

const DataTable = ({
  endpoint,
  headers,
  title,
  autoHeight = false,
  density = "standard",
  pageSize = 100,
  checkboxSelection = false,
  onEditCopyRow,
  singular,
  onRowClick = (params) => onEditCopyRow({ mode: "edit", row: params.row }),
  displayAddIcon = false,
  query,
  customerFooter,
  initialSort = {},
  allowEdit = true,
  allowDuplicate = true,
  allowDelete = true,
  tableActions,
}) => {

  const theme = useTheme();
  const { appData } = useAppDataContext();
  const { fetchTableData } = useFetchTableData();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [appDataName, setAppDataName] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (query && Object.keys(query).length !== 0) {
      const queryParams = Object.keys(query)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
        )
        .join("&");
      getTableData(`${endpoint}?${queryParams}`);
    } else {
      getTableData(endpoint);
    }
    // eslint-disable-next-line
  }, [endpoint]);

  async function getTableData(endpoint) {
    await fetchTableData(endpoint);
    setIsLoading(false);
  }

  useEffect(() => {
    setAppDataName(endpoint?.split("/").pop());
  }, [endpoint]);

  useEffect(() => {
    if (appData && appData[appDataName]) {
      setTableData(appData[appDataName]);
    }
    // eslint-disable-next-line
  }, [appData, endpoint]);

  const item = {
    field: "actions",
    headerName: "Acties",
    hideable: false,
    headerAlign: "center",
    width: 90,
    sortable: false,
    align: "center",
    renderCell: (params) => (
      <div className="action-button">
        <RowActions
          row={params.row}
          onEditCopyRow={onEditCopyRow}
          onDeleteRow={onDeleteRow}
          allowEdit={allowEdit}
          allowDuplicate={allowDuplicate}
          allowDelete={allowDelete}
        />
      </div>
    ),
  };

  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({
    keyProp: 0,
    show: false,
    id: "",
  });

  async function onDeleteRow(row) {
    setConfirmDeleteDialog((prevState) => ({
      ...prevState,
      show: true,
      keyProp: prevState.keyProp + 1,
      id: row._id,
    }));
  }

  const headersWithMenuItems = [...headers, item];

  const adjustedHeaders = headersWithMenuItems.map((header) => {
    if (header.label) {
      header.headerName = header.label;
    }

    if (!header.width) {
      header.flex = 1;
    }

    if (header.display === "textfield") {
      return {
        ...header,
      };
    }

    if (header.display === "autocomplete") {
      return {
        ...header,
      };
    }

    if (header.display === "date") {
      return {
        ...header,
        valueFormatter: (params) => formatToDutchDate(params.value),
      };
    }

    if (header.display === "currency") {
      return {
        ...header,

        type: "number",
        headerAlign: "left",
        align: "left",
        valueFormatter: (params) => {
          if (params.value === null || params.value === undefined) {
            return null;
          }
          const formattedValue = `€${params.value.toFixed(2)}`;
          return formattedValue.replace(".", ",");
        },
      };
    }

    if (header.display === "number") {
      return {
        ...header,

        headerAlign: "left",
        align: "left",
        cellClassName: header.editable ? "editable-cell" : "",
      };
    }

    return header;
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});

  useEffect(() => {
    const newVisibilityModel = headers.reduce((model, header) => {
      model[header.field] = header.visible !== false;
      return model;
    }, {});

    setColumnVisibilityModel(newVisibilityModel);
  }, [headers]);

  function getRowId(row) {
    return row._id;
  }

  const mobileItem = {
    field: "tableData",
    headerName: "Tabelgegevens",
    flex: 1,
    renderCell: (params) => (
      <div style={{ width: "100%", marginBottom: "12px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <div className="action-button">
            <RowActions
              row={params.row}
              onEditCopyRow={onEditCopyRow}
              onDeleteRow={onDeleteRow}
            />
          </div>
        </Box>
        <div>
          {adjustedHeaders.map((header) => {
            const value = header.valueGetter
              ? header.valueGetter({ row: params.row })
              : params.row[header.field];

            const formattedValue = header.valueFormatter
              ? header.valueFormatter({ value, row: params.row })
              : value;

            return (
              <div key={header.field} style={{ display: "flex" }}>
                <div
                  style={{
                    flex: "1",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {header.label}
                </div>
                <div
                  style={{
                    flex: "1",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {formattedValue}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ),
  };

  function CustomToolbar() {
    if (isLargeScreen) {
      return (
        <GridToolbarContainer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 10px 5px 10px",
            position: "relative",
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 style={{ margin: "5px 0 5px 0" }}>{title}</h1>
              {displayAddIcon && (
                <IconButton
                  onClick={() =>
                    onEditCopyRow({
                      mode: "new",
                    })
                  }
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              )}
            </div>

            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </div>

          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <GridToolbarQuickFilter style={{ width: 350, paddingTop: 12 }} />
          </div>
          <TableActions
            endpoint={endpoint}
            tableActions={tableActions}
            isLoading={(state) => setIsLoading(state)}
          />
        </GridToolbarContainer>
      );
    } else {
      return (
        <GridToolbarContainer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 10px 4px 10px",
            position: "relative",
          }}
          sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "6px",
            }}
          >
            <h1 style={{ margin: "5px 0 5px 0", fontSize: "1.4rem" }}>
              {title}
            </h1>
            {displayAddIcon && (
              <IconButton
                onClick={() =>
                  onEditCopyRow({
                    mode: "new",
                  })
                }
                size="large"
              >
                <AddIcon />
              </IconButton>
            )}
          </div>
        </GridToolbarContainer>
      );
    }
  }

  const [isLoading, setIsLoading] = useState(true);

  function handleRowClick(params, event) {
    if (event.target.closest(".action-button")) {
      return;
    } else {
      if (onRowClick) {
        onRowClick(params);
      }
    }
  }

  function CustomerFooter() {
    return (
      <Box
        sx={{
          borderTop: "1px solid rgba(224, 224, 224, 1)",
          ...(isLargeScreen
            ? {
                display: "flex",
                justifyContent: "space-between",
              }
            : {}),
        }}
      >
        <div
          style={
            customerFooter
              ? {
                  paddingLeft: "10px",
                  marginTop: "14px",
                  marginBottom: "14px",
                }
              : {}
          }
        >
          {customerFooter}
        </div>

        <GridPagination />
      </Box>
    );
  }

  return (
    <div>
      <StyledDataGrid
        onRowClick={handleRowClick}
        rows={tableData}
        autoHeight={autoHeight}
        columns={isLargeScreen ? adjustedHeaders : [mobileItem]}
        getRowId={getRowId}
        pageSize={5}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
        slots={{
          noRowsOverlay: () => <CustomNoRowsOverlay text={title} />,
          loadingOverlay: LinearProgress,
          toolbar: CustomToolbar,
          footer: CustomerFooter,
        }}
        loading={isLoading}
        sx={{
          "--DataGrid-overlayHeight": "300px",
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .editable-cell:focus-within": {
            outline: `1px solid ${theme.palette.primary.main}`,
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          ...(onRowClick && {
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }),
          ...(!isLargeScreen && {
            "& .MuiDataGrid-columnHeaders": {
              display: "none",
            },
          }),
        }}
        rowHeight={
          headers.find((item) => item.type === "image")
            ? headers.find((item) => item.type === "image").height || 100
            : undefined
        }
        getRowHeight={!isLargeScreen ? () => "auto" : undefined}
        initialState={{
          pagination: { paginationModel: { pageSize: pageSize } },
          sorting: {
            sortModel: [initialSort],
          },
        }}
        density={density}
        disableColumnMenu
      />
      <ConfirmDeleteTableRowDialog
        width="800px"
        title={singular || title}
        openDialog={confirmDeleteDialog.show}
        key={confirmDeleteDialog.keyProp}
        id={confirmDeleteDialog.id}
        endpoint={endpoint}
      />
    </div>
  );
};

export default DataTable;
