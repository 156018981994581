import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'; // Importeer CircularProgress
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Box from '@mui/material/Box';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ fileSource }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(true); // State voor het bijhouden van de laadstatus

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setLoading(false); // Zet loading op false wanneer het document is geladen
    }

    function goToPrevPage() {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }

    function goToNextPage() {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }

    const loader = (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
            <CircularProgress />
        </Box>
    );

    return (
        <div>
            <Document
                file={fileSource}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={loader}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            {!loading && numPages > 1 && (
                <nav style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                        Vorige pagina
                    </Button>
                    <Button
                        onClick={goToNextPage}
                        disabled={pageNumber >= numPages}
                    >
                        Volgende pagina
                    </Button>
                </nav>
            )}
        </div>
    );
};

export default PdfViewer;
