import React, { useEffect, useState } from 'react'
import { useRefresh } from '../Hooks/useRefresh'
import DataTable from '../Components/UI/DataTable'
import headers from '../Utils/Fields/transactionFields'
import { DialogModes } from '../Utils/DialogModes'
import PageContainer from '../Components/UI/PageContainer'
import endpoint from '../Utils/endpoint'
import AddEditTransactionDialog from '../Components/Dialogs/AddEditTransactionDialog'
import AddTransactionsDialog from '../Components/Dialogs/AddTransactionsDialog'
import { useAppDataContext } from '../Hooks/AppData/useAppDataContext'
import { useFetchTableData } from '../Context/AppData/useFetchTableData'
import DescriptionIcon from '@mui/icons-material/Description'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { enqueueSnackbar } from 'notistack'
import { useAxios } from '../Hooks/useAxios'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmDeleteDialog from '../Components/UI/ConfirmDeleteDialog'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

const Transactions = () => {
    useRefresh()
    const { Axios } = useAxios()
    const [openDialog, setOpenDialog] = useState(false)
    const { appData } = useAppDataContext()
    const { fetchTableData } = useFetchTableData()

   async  function handleClose() {
        await fetchTableData(endpoint.transaction)
        setOpenDialog(false)
    }

    const [patchCopyData, setPatchCopyData] = useState({})

    const handleEditCopy = (rowData) => {
        setPatchCopyData(rowData)
        setOpenDialog(true)
    }

    useEffect(() => {
        async function getInvoices() {
            await fetchTableData(endpoint.inbox)
        }
        getInvoices()
        // eslint-disable-next-line
    }, [])

    const [enhancedHeaders, setEnhancedHeaders] = useState(headers)

    useEffect(() => {
        const enhancedHeadersForDataTable = headers.map((header) => {
            if (header.field === 'invoicePresent') {
                return {
                    ...header,
                    renderCell: (params) => {
                        function getIcon(params) {
                            if (params.row.noInvoice) {
                                return (
                                    <Tooltip title="Transactie zonder factuur">
                                        <DescriptionIcon
                                            style={{ color: '#f49b70' }}
                                        />
                                    </Tooltip>
                                )
                            } else if (
                                appData?.inbox?.find(
                                    (invoice) =>
                                        invoice.transactionId === params.row._id
                                )
                            ) {
                                return (
                                    <Tooltip title="Factuur gevonden">
                                        <DescriptionIcon
                                            style={{ color: '#25c781' }}
                                        />
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <Tooltip title="Geen factuur gevonden">
                                        <DescriptionIcon
                                            style={{ color: 'lightgrey' }}
                                        />
                                    </Tooltip>
                                )
                            }
                        }
                        return <div>{getIcon(params)}</div>
                    },
                }
            }
            return header
        })
        setEnhancedHeaders(enhancedHeadersForDataTable)
    }, [appData])

    const [loadingNumbers, setLoadingNumbers] = useState(false)

    async function renumberInvoices() {
        setLoadingNumbers(true)
        enqueueSnackbar('Transacties hernummeren...', {
            variant: 'info',
        })
        await Axios(`${endpoint.transaction}/newNumbers`, 'GET')
        await fetchTableData(endpoint.transaction)
        enqueueSnackbar('Alle transactions zijn opnieuw genummerd', {
            variant: 'success',
        })
        setLoadingNumbers(false)
    }

    const [loadingDelete, setLoadingDelete] = useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false)

    async function deleteAllInvoices() {
        setLoadingDelete(true)
        enqueueSnackbar('Transacties verwijderen...', {
            variant: 'info',
        })
        await Axios(`${endpoint.transaction}/delete`, 'DELETE')
        await fetchTableData(endpoint.transaction)
        enqueueSnackbar('Alle transactions zijn verwijderd', {
            variant: 'success',
        })
        setLoadingDelete(false)
        setOpenConfirmDelete(false)
    }

    const [noTransaction, setNoTransactions] = useState(true)

    useEffect(() => {
        if (appData?.transactions?.length > 0) {
            setNoTransactions(false)
        }
    }, [appData])

    const [downloadAccountingLoading, setDownloadAccountingLoading] =
        useState(false)

    async function downloadAccounting() {
        setDownloadAccountingLoading(true)
        enqueueSnackbar('Boekhouding wordt gedownload...', {
            variant: 'default',
        })

        let filename = 'Boekhouding.zip'

        const { year, quarter } = appData?.transactions[0]
        if (year && quarter) {
            filename = `Boekhouding-${year}-Q${quarter}.zip`
        }

        const response = await Axios(
            `${endpoint.transaction}/download`,
            'DOWNLOAD',
            filename
        )

        if (response) {
            await fetchTableData(endpoint.transaction)
            enqueueSnackbar('Boekhouding succesvol gedownload', {
                variant: 'success',
            })
        } else {
            await fetchTableData(endpoint.transaction)
            enqueueSnackbar(
                'Er ging iets mis met het downloaden van de boekhouding',
                {
                    variant: 'error',
                }
            )
        }
        setDownloadAccountingLoading(false)
    }

    useEffect(() => {
        async function checkTransactions() {
            let needUpdate = false
            if (appData?.inbox && appData?.transactions) {
                for (const inboxItem of appData.inbox) {
                    if (inboxItem.transactionId) {
                        const transactionExists = appData.transactions.find(
                            (transaction) =>
                                transaction._id === inboxItem.transactionId
                        )

                        let reason =
                            'Koppeling is verwijdert omdat transactie ontbreekt'
                        if (transactionExists?.noInvoice) {
                            reason =
                                'Koppeling verwijderd: Factuur niet vereist voor de betreffende transactie.'
                        }

                        if (!transactionExists || transactionExists.noInvoice) {
                            let patchData = { ...inboxItem }
                            patchData.transactionId = null

                            try {
                                await Axios(endpoint.inbox, 'PUT', patchData)
                                enqueueSnackbar(reason, {
                                    variant: 'info',
                                })
                                needUpdate = true
                            } catch (error) {
                                console.log(error)
                                enqueueSnackbar(
                                    'Er ging iets mis met het verwijderen van de koppeling',
                                    {
                                        variant: 'error',
                                    }
                                )
                            }
                        }
                    }
                }
            }
            if (needUpdate) {
                await fetchTableData(endpoint.inbox)
            }
        }

        checkTransactions()
        // eslint-disable-next-line
    }, [appData])

    const tableActions = (
        <div>
            <MenuItem
                onClick={renumberInvoices}
                disabled={loadingNumbers || noTransaction}
            >
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                Hernummer transacties
            </MenuItem>
            <MenuItem
                onClick={() => setOpenConfirmDelete(true)}
                disabled={loadingDelete || noTransaction}
            >
                <ListItemIcon>
                    <DeleteIcon />
                </ListItemIcon>
                Verwijder alle transacties
            </MenuItem>
            <MenuItem
                onClick={downloadAccounting}
                disabled={noTransaction || downloadAccountingLoading}
            >
                <ListItemIcon>
                    <CloudDownloadIcon />
                </ListItemIcon>
                Boekhouding downloaden
            </MenuItem>
        </div>
    )

    return (
        <PageContainer width="xl">
            <DataTable
                headers={enhancedHeaders}
                title="Transacties"
                singular="Transactie"
                endpoint={endpoint.transaction}
                autoPageSize
                autoHeight
                onEditCopyRow={handleEditCopy}
                initialSort={{ field: 'date', sort: 'date' }}
                allowDuplicate={false}
                tableActions={tableActions}
                density="compact"
                displayAddIcon={true}
            />
            <AddEditTransactionDialog
                openDialog={openDialog}
                afterClose={handleClose}
                patchData={patchCopyData.row}
                dialogMode={DialogModes[patchCopyData.mode]}
            />
            <AddTransactionsDialog />
            <ConfirmDeleteDialog
                title={'Transacties'}
                handleSave={() => deleteAllInvoices()}
                openDialog={openConfirmDelete}
                width="800px"
                afterclose={() => {
                    setOpenConfirmDelete(false)
                }}
            />
        </PageContainer>
    )
}

export default Transactions
