const fields = [
  {
    field: "companyName",
    required: true,
    label: "Bedrijfsnaam",
    value: "",
    col: 4,
    display: "textfield",
  },
  {
    field: "streetNameNumber",
    required: true,
    label: "Straat",
    value: "",
    col: 4,
    display: "textfield",
  },
  {
    field: "postalCode",
    required: true,
    label: "Postcode",
    value: "",
    col: 4,
    display: "textfield",
  },
  {
    field: "city",
    required: true,
    label: "Plaatsnaam",
    value: "",
    col: 4,
    display: "textfield",
  },
  {
    field: "phoneNumber",
    required: true,
    label: "Telefoon",
    value: "",
    col: 4,
    display: "textfield",
  },
  {
    field: "email",
    required: true,
    label: "Emailadres",
    value: "",
    col: 4,
    display: "textfield",
  },
  {
    field: "chamberOfCommerce",
    required: true,
    label: "KvK",
    value: "",
    col: 4,
    display: "textfield",
    visible: false,
  },
  {
    field: "vatNumber",
    required: true,
    label: "BTW-nummer",
    value: "",
    col: 4,
    display: "textfield",
    visible: false,
  },
  {
    field: "bankAccount",
    required: true,
    label: "Rekeningnummer",
    value: "",
    col: 4,
    display: "textfield",
    visible: false,
  },
];

export default fields;
