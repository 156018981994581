const fields = [
  {
    field: "date",
    required: true,
    label: "Datum",
    value: "",
    col: 4,
    display: "date",
  },
  {
    field: "description",
    required: true,
    label: "Omschrijving",
    value: "",
    col: 8,
    display: "textfield",
    width: 400,
  },
  {
    field: "tariff",
    required: true,
    label: "Tarief",
    value: "",
    col: 6,
    display: "currency",
    renderHeader: () => (
      <span>
        Tarief <small style={{ fontWeight: "normal" }}> ex. BTW</small>
      </span>
    ),
  },
  {
    field: "quantity",
    required: true,
    label: "Aantal",
    value: "",
    col: 6,
    display: "number",
    width: 75,
  },
  {
    field: "amount",
    label: "Bedrag",
    value: "",
    display: "currency",
    readOnly: true,
    showInForm: false,
    renderHeader: () => (
      <span>
        Bedrag <small style={{ fontWeight: "normal" }}> ex. BTW</small>
      </span>
    ),
  },
  {
    field: "amountVatIncluded",
    label: "Tot. Bedrag",
    value: "",
    display: "currency",
    showInForm: false,
    readOnly: true,
    renderHeader: () => (
      <span>
        Bedrag <small style={{ fontWeight: "normal" }}> incl. BTW</small>
      </span>
    ),
  },
];

export default fields;
