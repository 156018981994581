import Grid from "@mui/material/Grid";
import BusinessCard from "../Components/UI/BusinessCard";
import PageContainer from "../Components/UI/PageContainer";
import { useAppDataContext } from "../Hooks/AppData/useAppDataContext";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import endpoint from "../Utils/endpoint";
import { useFetchTableData } from "../Context/AppData/useFetchTableData";
import Typography from "@mui/material/Typography";
import * as React from "react";
import headers from "../Utils/Fields/invoiceDescriptionFields";
import DataTable from "../Components/UI/DataTable";
import { DialogModes } from "../Utils/DialogModes";
import AddEditInvoiceDescriptionDialog from "../Components/Dialogs/AddEditInvoiceDescriptionDialog";
import { useRefresh } from "../Hooks/useRefresh";
import FormatCurrency from "../Utils/FormatCurrency";
import { styled } from "@mui/system";
import DownloadButton from "../Components/UI/DownloadButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const InvoiceTitle = styled(Typography)(({ theme }) => ({
  margin: 0,
  // fontSize: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.4rem",
  },
}));

const SingleInvoice = () => {
  useRefresh();
  const { appData } = useAppDataContext();
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const [company, setCompany] = useState({});
  const [invoice, setInvoice] = useState({});
  const { fetchTableData } = useFetchTableData();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [openDialog, setOpenDialog] = useState(false);

  function handleClose() {
    setOpenDialog(false);
  }

  const [patchCopyData, setPatchCopyData] = useState({});

  const handleEditCopy = (rowData) => {
    setPatchCopyData(rowData);
    setOpenDialog(true);
  };

  useEffect(() => {
    fetchTableData(endpoint.businessDetails);
    fetchTableData(endpoint.customers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const customerDetails = appData?.invoices?.find(
      (invoice) => invoice._id === id,
    ).customer;
    setCustomer(customerDetails);
  }, [appData, id]);

  useEffect(() => {
    const companyDetails = appData?.invoices?.find(
      (invoice) => invoice._id === id,
    ).businessDetails;
    setCompany(companyDetails);
  }, [appData, id]);

  useEffect(() => {
    const invoice = appData?.invoices?.find((invoice) => invoice._id === id);
    setInvoice(invoice);
  }, [appData, id]);

  const contentOfCard = (
    <Typography variant="body2" color="text.secondary" component="div">
      <p style={{ margin: 0 }}>{customer.street}</p>
      <p style={{ margin: 0 }}>
        {customer.postalCode} {customer.city}
      </p>
    </Typography>
  );

  const contentOfCompanyCard = (
    <Typography variant="body2" color="text.secondary" component="div">
      <p style={{ margin: 0 }}>{company.streetNameNumber}</p>
      <p style={{ margin: 0 }}>
        {company.postalCode} {company.city}
      </p>
    </Typography>
  );

  const expandCustomerContent = (
    <Typography variant="body2" color="text.secondary" component="div">
      <p style={{ margin: 0 }}>{customer.email}</p>
      <p style={{ margin: 0 }}>{customer.secondaryEmail || ""}</p>
    </Typography>
  );

  const expandBusinessContent = (
    <Typography variant="body2" color="text.secondary" component="div">
      <p style={{ margin: 0 }}>{company.email}</p>
      <p style={{ margin: 0 }}>{company.phoneNumber}</p>
      <p style={{ margin: 0 }}>{company.chamberOfCommerce}</p>
      <p style={{ margin: 0 }}>{company.bankAccount}</p>
    </Typography>
  );

  const [enhancedHeaders, setEnhancedHeaders] = useState(headers);

  useEffect(() => {
    const updatedHeaders = headers.map((header) => {
      if (header.field === "amount") {
        return {
          ...header,
          valueGetter: (params) => params.row.tariff * params.row.quantity,
          valueFormatter: (params) => {
            const total = params.value;
            return `€${total.toFixed(2)}`.replace(".", ",");
          },
        };
      }
      if (header.field === "amountVatIncluded") {
        return {
          ...header,
          valueGetter: (params) =>
            params.row.tariff * params.row.quantity * 1.21,
        };
      }
      return header;
    });
    setEnhancedHeaders(updatedHeaders);
  }, []);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    const total = appData[id]?.reduce(
      (acc, item) => acc + item.quantity * item.tariff,
      0,
    );
    setTotal(total);
  }, [appData, id]);

  const customerFooter = total > 0 && (
    <div style={{ display: "flex" }}>
      <Typography
        variant="body2"
        color="text.primary"
        style={{ width: "150px" }}
        component="div"
      >
        <p style={{ margin: 0 }}>Totaal ex. BTW: </p>
        <p style={{ margin: 0 }}>Totaal BTW:</p>
        <p style={{ margin: 0 }}>Totaal incl. BTW</p>
      </Typography>
      <Typography variant="body2" color="text.primary" component="div">
        <p style={{ margin: 0 }}>{FormatCurrency(total)} </p>
        <p style={{ margin: 0 }}>{FormatCurrency(total * 0.21)}</p>
        <p style={{ margin: 0 }}>{FormatCurrency(total * 1.21)}</p>
      </Typography>
    </div>
  );

  return (
    <PageContainer>
      <Grid container>
        <Grid item xs={11} md={10}>
          <InvoiceTitle variant="h1">
            Factuur {invoice.internalReference}
          </InvoiceTitle>
        </Grid>
        <Grid
          item
          xs={1}
          md={2}
          style={{ paddingTop: isLargeScreen ? "11px" : "0" }}
        >
          <DownloadButton
            id={id}
            fileName={`${customer.companyName} - ${invoice.invoiceNumber}`}
            disabled={!appData[id] || appData[id].length < 1}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{ xs: 2, md: 10 }}
        sx={{ paddingTop: 2, paddingBottom: 2 }}
      >
        <Grid item xs={12} md={6}>
          <BusinessCard
            title={customer.companyName}
            subheader={`T.a.v. ${customer.fao}`}
            contentOfCard={contentOfCard}
            expandContent={expandCustomerContent}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BusinessCard
            title={company.companyName}
            contentOfCard={contentOfCompanyCard}
            expandContent={expandBusinessContent}
          />
        </Grid>
      </Grid>
      <DataTable
        headers={enhancedHeaders}
        title="Omschrijving"
        endpoint={`${endpoint.invoiceDescriptions}/${id}`}
        autoHeight
        onEditCopyRow={handleEditCopy}
        displayAddIcon
        density="compact"
        customerFooter={customerFooter}
        initialSort={{ field: "date", sort: "asc" }}
      />
      <AddEditInvoiceDescriptionDialog
        openDialog={openDialog}
        afterClose={handleClose}
        patchData={patchCopyData.row}
        dialogMode={DialogModes[patchCopyData.mode]}
        endpoint={`${endpoint.invoiceDescriptions}/${id}`}
      />
    </PageContainer>
  );
};

export default SingleInvoice;
