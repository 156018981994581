import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useAxios } from "../../Hooks/useAxios";
import { useFetchTableData } from "../../Context/AppData/useFetchTableData";
import { useSnackbar } from "notistack";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import endpoint from "../../Utils/endpoint";
import ButtonToggler from "../UI/ToggleButton";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddTransactionsDialog({ openDialog = false, width = "600px" }) {
  const [open, setOpen] = useState(openDialog);
  const { Axios } = useAxios();
  const navigate = useNavigate();
  const { fetchTableData } = useFetchTableData();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    file: undefined,
    year: new Date().getFullYear(),
    quarter: 1,
  });

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    await Axios(endpoint.transaction, "POST", formData);
    handleClose();
    await fetchTableData(endpoint.transaction);
    setOpen(false);
    enqueueSnackbar(`Transacties succesvol geupload`, {
      variant: "success",
    });
    setLoading(false);
    navigate("/transacties");
  };

  const csvToJson = (csv) => {
    const lines = csv.split("\n");

    const objects = lines.map((line) => {
      const columns = line.split(",");
      return {
        date: columns[0],
        description: columns[3],
        inclVat: +columns[10],
        extraInfo: columns[17],
      };
    });

    return objects.filter(
      (obj) => obj.date.trim() !== "" && obj.description !== undefined,
    );
  };

  const [loadingFile, setLoadingFile] = useState(false);

  const handleFileChange = (event) => {
    setLoadingFile(true);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const csvLines = csvToJson(text);
        if (csvLines.length === 0) {
          enqueueSnackbar(`Dit lijkt een leeg bestand te zijn`, {
            variant: "error",
          });
          setLoadingFile(false);
          return;
        }
        setFormData({
          ...formData,
          file: csvLines,
        });
        enqueueSnackbar(`Bestand succesvol toegevoegd`, {
          variant: "success",
        });
      };
      reader.readAsText(file);
    }
    setLoadingFile(false);
  };

  const yearOptions = [2024, 2025, 2026, 2027];
  const quarterlyOptions = [
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 },
  ];

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: width, // Set your width here
            },
          },
        }}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Typography variant="h1">Transacties toevoegen</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              Voeg transacties toe vanuit een bankexport
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <ButtonToggler
                options={quarterlyOptions}
                defaultValue={1}
                passSelectedOptionToParent={(value) =>
                  setFormData({ ...formData, quarter: value })
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <ButtonToggler
                options={yearOptions}
                defaultValue={new Date().getFullYear()}
                passSelectedOptionToParent={(value) =>
                  setFormData({ ...formData, year: value })
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingTop: 0 }}
              sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
            >
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                disabled={formData?.file || loadingFile}
              >
                Upload csv{" "}
                {loadingFile && (
                  <CircularProgress size="1rem" sx={{ marginLeft: 0.7 }} />
                )}
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv"
                />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sluiten</Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={
              !formData?.file ||
              !formData?.year ||
              !formData?.quarter ||
              loading
            }
          >
            Opslaan
            {loading && (
              <CircularProgress size="1rem" sx={{ marginLeft: 0.7 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddTransactionsDialog;
