import { AppDataContext } from "../../Context/AppData/AppDataContext";
import { useContext } from "react";

export const useAppDataContext = () => {
  const context = useContext(AppDataContext);

  if (!context) {
    throw Error(
      "useAuthContext must be used inside an WorkoutsContextProvider",
    );
  }

  return context;
};
