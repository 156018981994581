import {vatOptions, costTypes, yearOptions, quarterOptions} from "../options";

const fields = [
  {
    field: "invoiceNumber",
    label: "Factuurnummer",
    value: "",
    col: 4,
    display: "textfield",
    readOnly: true,
    width: 200,
  },
  {
    field: "date",
    required: true,
    label: "Datum",
    value: "",
    col: 4,
    display: "date",
    width: 100,
  },
  {
    field: "supplier",
    required: true,
    label: "Klant/leverancier",
    value: "",
    col: 4,
    display: "textfield",
  },
  {
    field: "costType",
    label: "Soort",
    value: "",
    col: 6,
    display: "autocomplete",
    options: costTypes.flatMap((type) => type.options),
    groupBy: (option) => {
      const group = costTypes.find((type) => type.options.includes(option));
      return group ? group.label : undefined;
    },
    renderCell: (params) => {
      let costTypeLabel = "";
      for (const type of costTypes) {
        const costType = type.options.find(
          (option) => option.value === params.value,
        );
        if (costType) {
          costTypeLabel = costType.label;
          break;
        }
      }
      return <div>{costTypeLabel}</div>;
    },
  },
  {
    field: "vatTariff",
    label: "BTW tarief",
    col: 6,
    display: "autocomplete",
    width: 100,
    options: vatOptions,
    renderCell: (params) => {
      const vat = vatOptions.find((vat) => vat.value === params.value);
      return <div>{vat?.label}</div>;
    },
    required: true,
  },
  {
    field: "exVat",
    label: "Ex. BTW",
    value: "",
    col: 4,
    display: "currency",
    readOnly: true,
    width: 100,
  },
  {
    field: "vat",
    label: "BTW",
    value: "",
    col: 4,
    display: "currency",
    readOnly: true,
    width: 100,
  },
  {
    field: "inclVat",
    label: "Incl. BTW",
    value: "",
    col: 4,
    display: "currency",
    width: 100,
    required: true,
  },
  {
    field: "remark",
    label: "Opmerking",
    value: "",
    col: 8,
    display: "textfield",
    width: 100,
    visible: false,
  },
  {
    field: "noInvoice",
    label: "Transactie zonder factuur",
    value: "",
    col: 4,
    display: "toggle",
    visible: false,
    renderCell: (params) => {
      return <div>{params.value ? "Zonder factuur" : "Met factuur"}</div>;
    },
  },
  {
    field: "year",
    label: "Jaar",
    col: 6,
    display: "autocomplete",
    options: yearOptions,
    required: true,
    visible: false,
    onlyShowOnCreate: true,
  },
  {
    field: "quarter",
    label: "Kwartaal",
    col: 6,
    display: "autocomplete",
    options: quarterOptions,
    required: true,
    visible: false,
    onlyShowOnCreate: true,
  },
  {
    field: "invoicePresent",
    label: "Status",
    width: 75,
    sortable: false,
  },
];

export default fields;
