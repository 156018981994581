import { useRefresh } from '../Hooks/useRefresh'
import { useEffect, useState } from 'react'
import DataTable from '../Components/UI/DataTable'
import headers from '../Utils/Fields/invoiceFields'
import AddEditInvoiceDialog from '../Components/Dialogs/AddEditInvoiceDialog'
import { DialogModes } from '../Utils/DialogModes'
import endpoint from '../Utils/endpoint'
import { useAppDataContext } from '../Hooks/AppData/useAppDataContext'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../Components/UI/PageContainer'

const Invoices = () => {
    useRefresh()
    const navigate = useNavigate()
    const { appData } = useAppDataContext()
    const [openDialog, setOpenDialog] = useState(false)

    function handleClose() {
        setOpenDialog(false)
    }

    const [patchCopyData, setPatchCopyData] = useState({})

    const handleEditCopy = (rowData) => {
        setPatchCopyData(rowData)
        setOpenDialog(true)
    }

    const [enhancedHeaders, setEnhancedHeaders] = useState(headers)

    useEffect(() => {
        const enhancedHeaders = headers.map((header) => {
            if (header.field === 'customer') {
                return {
                    ...header,
                    renderCell: (params) => {
                        return <div>{params?.value?.companyName}</div>
                    },
                    valueFormatter: (params) => {
                        return params.value.companyName
                    },
                }
            }
            if (header.field === 'businessDetails') {
                return {
                    ...header,
                    renderCell: (params) => {
                        return <div>{params?.value?.companyName}</div>
                    },
                    valueFormatter: (params) => {
                        return params.value.companyName
                    },
                }
            }
            return header
        })
        setEnhancedHeaders(enhancedHeaders)
    }, [appData])

    return (
        <PageContainer>
            <DataTable
                headers={enhancedHeaders}
                title="Facturen"
                singular="Factuur"
                endpoint={endpoint.invoices}
                autoPageSize
                autoHeight
                onEditCopyRow={handleEditCopy}
                initialSort={{ field: 'invoiceDate', sort: 'asc' }}
                onRowClick={(params) => {
                    navigate(`/facturen/${params.row._id}`)
                }}
                displayAddIcon
                density="compact"
            />
            <AddEditInvoiceDialog
                openDialog={openDialog}
                afterClose={handleClose}
                patchData={patchCopyData.row}
                dialogMode={DialogModes[patchCopyData.mode]}
            />
        </PageContainer>
    )
}

export default Invoices
