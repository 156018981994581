import AddEditDialog from "../UI/AddEditDialog";
import fields from "../../Utils/Fields/invoiceDescriptionFields";

const AddEditInvoiceDescriptionDialog = ({
  openDialog,
  afterClose = () => {},
  dialogMode,
  patchData,
  transformData,
  endpoint,
}) => {
  return (
    <AddEditDialog
      openDialog={openDialog}
      title="Omschrijving"
      fields={fields}
      afterClose={afterClose}
      width="900px"
      endpoint={endpoint}
      dialogMode={dialogMode}
      patchData={patchData}
      transformData={transformData}
    />
  );
};

export default AddEditInvoiceDescriptionDialog;
