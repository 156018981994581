import * as React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { useState } from "react";
import AddEditBusinessDetailsDialog from "../Dialogs/AddEditBusinessDetailsDialog";
import AddEditCustomerDialog from "../Dialogs/AddEditCustomerDialog";
import { DialogModes } from "../../Utils/DialogModes";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { styled } from "@mui/system";
import AddEditInvoiceDialog from "../Dialogs/AddEditInvoiceDialog";
import { useAuthContext } from "../../Hooks/Auth/useAuthContext";
import AddTransactionsDialog from "../Dialogs/AddTransactionsDialog";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";

const useStyles = styled((theme) => ({
  StaticTooltipLabel: {
    whiteSpace: "nowrap",
    maxWidth: "none",
  },
}));

export default function ControlledOpenSpeedDial() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [keyProp, setKeyProp] = React.useState(0);
  const { user } = useAuthContext();

  const handleSelection = (action) => {
    setSelectedAction(action);
    setKeyProp((prevKeyProp) => prevKeyProp + 1);
  };

  let dynamicActions;

  dynamicActions = [
    {
      icon: <AddBusinessIcon />,
      name: "Bedrijf toevoegen",
      component: (
        <AddEditBusinessDetailsDialog
          key={keyProp}
          openDialog={true}
          DialogMode={DialogModes.new}
        />
      ),
    },
    {
      icon: <PersonIcon />,
      name: "Klant toevoegen",
      component: (
        <AddEditCustomerDialog
          key={keyProp}
          openDialog={true}
          DialogMode={DialogModes.new}
        />
      ),
    },

    {
      icon: <InsertDriveFileIcon />,
      name: "Nieuwe factuur",
      component: (
        <AddEditInvoiceDialog
          key={keyProp}
          openDialog={true}
          DialogMode={DialogModes.new}
        />
      ),
    },
  ];

  if (user?.accountingModule) {
    dynamicActions.splice(2, 0, {
      icon: <AlignHorizontalLeftIcon />,
      name: "Banktransacties toevoegen",
      component: <AddTransactionsDialog key={keyProp} openDialog={true} />,
    });
  }

  const classes = useStyles();

  return (
    <div>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {dynamicActions?.map((action) => (
          <SpeedDialAction
            classes={{ staticTooltipLabel: classes.StaticTooltipLabel }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleSelection(action)}
            FabProps={{ size: "large" }}
            tooltipOpen
          />
        ))}
      </SpeedDial>
      {selectedAction && selectedAction.component}
    </div>
  );
}
