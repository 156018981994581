import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useAxios } from "../../Hooks/useAxios";
import { useFetchTableData } from "../../Context/AppData/useFetchTableData";
import { useSnackbar } from "notistack";
import { useState } from "react";

const ConfirmDeleteTableRowDialog = ({
  width = "1200px",
  title,
  openDialog = false,
  key,
  id,
  endpoint,
}) => {
  const [open, setOpen] = useState(openDialog);
  const { Axios } = useAxios();
  const { fetchTableData } = useFetchTableData();
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = async () => {
    await Axios(endpoint, "DELETE", id);
    await fetchTableData(endpoint);
    setOpen(false);
    enqueueSnackbar(`${title} succesvol verwijderd`, {
      variant: "success",
    });
    setOpen(false);
  };

  return (
    <ConfirmDeleteDialog
      width={width}
      title={title}
      openDialog={open}
      key={key}
      endpoint={endpoint}
      handleSave={handleSave}
    />
  );
};

export default ConfirmDeleteTableRowDialog;
